export default class {
    constructor() {
        this.$panel = $('.p-header_panel');
        this.$spBtn = $('.p-header_spBtn a');
    }
    init(){
        this.setEvent();
    }
    setEvent(){
        this.$spBtn.on('click', ()=>{
            if (this.$panel.hasClass('isOpen')) {
                this.closeSpNav();
            } else {
                this.openSpNav();
            }
            return false;
        });
    }
    openSpNav(){
        this.$panel.addClass('isOpen');
    }
    closeSpNav(){
        this.$panel.removeClass('isOpen');
    }
}
