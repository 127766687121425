export default class {
    constructor() {
        this.$cats = $('.js-cats');
        this.LENGTH = this.$cats.length;
    }
    init(){
        this.setEvent();
    }
    setEvent(){
        let array = [];

        for (var i = 0; i < this.LENGTH; i++) {
            array.push(i+1)
        }

        let shuffleArray = (arr) => {
            var i, j, tmp, length;
            for (length = arr.length, i = length - 1; i > 0; i--) {
                j = Math.floor(Math.random() * (i + 1));
                tmp = arr[i];
                arr[i] = arr[j];
                arr[j] = tmp;
            }
            return arr;
        }

        array = shuffleArray(array);

        this.$cats.each((i, el)=>{
            let $el = $(el);
            let $img = $el.find('img');

            $img.attr('src', app.themeUrl + 'assets/img/common/cats/cat_0' + array[i] + '.png');
        });
    }
}
