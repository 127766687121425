export default class {
    constructor() {
        this.$obj = $({});
    }
    init(){
        this.setEvent();
    }
    setEvent(){
        $(window)
            .on('load resize',()=>{
                this.switchWindow();
            })
    }
    switchWindow(){
        if(app.size.winW <= app.size.breakPoint && this.$flag != 'sp') {
            this.$flag = 'sp';

            this.switchSP();
        } else if(app.size.winW > app.size.breakPoint && this.$flag != 'pc') {
            this.$flag = 'pc';

            this.switchPC();
        }

        if(app.size.winW <= app.size.breakPointWide && this.$flag2 != 'small') {
            this.$flag2 = 'small';

            this.switchMin();
        } else if(app.size.winW > app.size.breakPointWide && this.$flag2 != 'wide') {
            this.$flag2 = 'wide';

            this.switchWide();
        }
    }
    switchPC(){
        this.$obj.trigger('SWITCH_PC');
    }
    switchSP(){
        this.$obj.trigger('SWITCH_SP');
    }
    switchWide(){
        this.$obj.trigger('SWITCH_WIDE');
    }
    switchMin(){
        this.$obj.trigger('SWITCH_MIN');
    }
}
