export default class {
    constructor() {
        this.$el = $('.loader');
        this.$cat = this.$el.find('img');
    }
    init(){
        this.setEvent();
    }
    setEvent(){

        let min = 1;
        let max = 5;
        let num = Math.floor( Math.random() * (max + 1 - min) ) + min ;

        let newsrc = this.$cat.attr('data-src').replace('cat_01', 'cat_0'+num);
        this.$cat.attr('src', newsrc).css('opacity', 1);

        $(window).on('load', ()=>{
            TweenMax.to(this.$el, .8, {
                ease: Power3.easeOut,
                'opacity': 0,
                delay: .3,
                onComplete: ()=>{
                    this.$el.css('display', 'none');
                }
            });
        });
    }
}
