/*
 * ============================================
 * GLOBAL
 * ============================================
**/

//UTILITY
import Env from './_app/class-utility/Env';

//HELPER
import Size from './_app/helper/size';
import Switcher from './_app/helper/switcher';
class App extends TinyEmitter{
    constructor() {
        super();

        //UTILITY
        this.env = new Env();
        this.env.init();

        //HELPER
        this.size = new Size();

        this.$app = $('#app');
        this.$obj = $({});
        this.spNavState = false;
        this.themeUrl = $('#app').attr('data-tmpdir');
    }
    start(){
        this.emit('START_BEFORE');

        this.switcher = new Switcher();
        this.switcher.init();

        $(()=>{
            this._startpage();
            this.emit('START_AFTER');
        });
    }
    _startpage(){
        this.page = new this.page();
        this.page.init();
    }
}

window.app = new App();



/*
 * ============================================
 * APP
 * ============================================
**/

import Common from './_controllers/controller_common';
import Access from './_controllers/controller_access';
import About from './_controllers/controller_about';

class Page extends Common{
    constructor() {
        super();
    }
    init(){
        super.init();

        this.access = new Access();
        this.access.init();

        this.about = new About();
        this.about.init();
    }
}

app.page = Page;
app.start();
