import Cats from '../_modules/_common/cats';
import Loader from '../_modules/_common/loader';
import Nav from '../_modules/_common/nav';
import Scroll from '../_modules/_common/scroll';

export default class {
    constructor() {
    }
    init(){
        this.cats = new Cats();
        this.cats.init();

        this.loader = new Loader();
        this.loader.init();

        this.nav = new Nav();
        this.nav.init();

        this.scroll = new Scroll();
        this.scroll.init();
    }
}
