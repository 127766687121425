export default class {
    constructor(param) {
        this.zoom_pc = param.zoom_pc;
        this.zoom_sp = param.zoom_sp;
        this.controlIcon = param.nav;
        this.elId = param.$el_ID;
        this.pinW = 100;
        this.pinH = 113;
        this.name = param.name;
        this.enableScrollWheel = false;
        this.enableDrag = true;
        this.style = [
            {
                "featureType": "water",
                "elementType": "geometry.stroke",
                "stylers": [
                    { "hue": "#ffa200" },
                    { "gamma": 0.89 },
                    { "color": "#dfe7e1" },
                    { "weight": 0.1 },
                    { "saturation": 9 },
                    { "lightness": 45 },
                    { "visibility": "simplified" }
                ]
            }
        ];
        this.marker = [];
        this.pinData = param.pinData;
        this.adjustLat = 0.0001;
    }
    init(){
        if (document.getElementById(this.elId) == null) {
            return;
        }

        this._setEvents();
    }
    _setEvents(){
        $(window)
            .on('load', ()=>{
                this._setMap();
            })
            .on('resize', ()=>{
                this._update();
            });
    }
    _update(){
        var center = this.map.getCenter();
        google.maps.event.trigger(this.map, "resize");
        this.map.setCenter(center);

        if ($(window).width() > app.size.breakPoint) {
            this.map.setZoom(this.zoom_pc);
        }else{
            this.map.setZoom(this.zoom_sp);
        }
    }
    _setMap(){
        this._renderMap();
        this._setPin();
        this._setStyle();
        this._getCenterLatLng();
        this._update();
    }
    _renderMap(){
        let myOptions = {
            zoom: this.zoom_pc,
            center: new google.maps.LatLng(this._getCenterLatLng().lat, this._getCenterLatLng().lng),
            scrollwheel: this.enableScrollWheel,
            draggable: this.enableDrag,
            disableDefaultUI: this.controlIcon,
            mapTypeControlOptions: {
                mapTypeIds: ['sample', google.maps.MapTypeId.ROADMAP]
            }
        };

        this.map = new google.maps.Map(document.getElementById(this.elId), myOptions);
    }
    _setPin(){
        for (var i = 0; i < this.pinData.length; i++) {
            let pinData = this.pinData[i];

            let markerLatLng = new google.maps.LatLng({lat: pinData['lat'], lng: pinData['lng']});

            let icon = new google.maps.MarkerImage(
                app.themeUrl + '/assets/img/common/parts/pin.png',
                new google.maps.Size(this.pinW, this.pinH),
                new google.maps.Point(0, 0)
            );

            icon.scaledSize = new google.maps.Size(this.pinW, this.pinH);

            this.marker[i] = new google.maps.Marker({
                position: markerLatLng,
                map: this.map,
                icon: icon
            });
        }
    }
    _setStyle(){
        let sampleType = new google.maps.StyledMapType(this.style, { name: this.name });

        this.map.mapTypes.set('sample', sampleType);
        this.map.setMapTypeId('sample');
    }
    /*
        複数ある住所の中心を計算
     */
    _getCenterLatLng(){
        let lat = [];
        let lng = [];

        for (var i = 0; i < this.pinData.length; i++) {
            let pinData = this.pinData[i];

            lat.push(pinData['lat']);
            lng.push(pinData['lng']);
        }

        let max_lat = Math.max.apply(null, lat);
        let min_lat = Math.min.apply(null, lat);
        let max_lng = Math.max.apply(null, lng);
        let min_lng = Math.min.apply(null, lng);

        let dif_lat = max_lat - min_lat;
        let dif_lng = max_lng - min_lng;

        return {
            lat: (dif_lat / 2) + min_lat + this.adjustLat,
            lng: (dif_lng / 2) + min_lng
        }
    }
}
