export default class {
    constructor() {
    }
    init(){
        if ($('#pageAbout').length == 0) { return };

        this.setPosition();
    }
    setPosition(){
        let $list = $('._person_list');
        let $okami = $('._person_item.-okami');

        app.switcher.$obj.on('SWITCH_PC', ()=>{
            $list.children().eq(3).before($okami);
        });

        app.switcher.$obj.on('SWITCH_SP', ()=>{
            $list.prepend($okami);
        });
    }
}
