export default class {
    constructor() {
        this.breakPoint = 767;
        this.breakPointWide = 1024;

        this.winW = $(window).width();
        $(window).on('load resize',()=>{this.winW = $(window).width();})

        // this.winH = $(window).height();
        this.winH = window.innerHeight;
        $(window).on('load resize',()=>{this.winH = window.innerHeight;})

        this.scrollH = $(window).scrollTop();
        $(window).on('load resize scroll',()=>{this.scrollH = $(window).scrollTop();})
    }
}
