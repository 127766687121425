export default class {
    constructor() {
        this.$btn = $('.js-scroll');
        this.speed = .8;
    }
    init(){
        this.setEvent();
    }
    setEvent(){

        let adjust = 0;

        if (app.env.isMobile) {
            adjust = 52;
        }

        $(document)
            .on('click', '.js-scroll', (e)=>{
                this.href = $(e.currentTarget).attr('href');
                this.target = $(this.href == "#" || this.href == "" ? 'html' : this.href);
                this.position = this.target.offset().top;

                TweenMax.to('body,html', this.speed, {
                    ease: Power3.easeInOut,
                    scrollTop:this.position - adjust
                });

                return false;
            })
    }
}
