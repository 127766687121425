import Map from '../_modules/_common/map';

export default class {
    constructor() {
    }
    init(){
        if ($('#pageAccess').length == 0) { return };

        this.accessMap_shop = new Map({
            $el_ID: 'accessMap',
            nav: false,
            zoom_pc: 13,
            zoom_sp: 17,
            name: 'やまねこ',
            pinData: [{
                name: 'やまねこ',
                lat: 37.066823,
                lng: 138.775947
            }]
        });
        this.accessMap_shop.init();
    }
}
